.get {
  margin-left: 2rem;
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.1rem solid var(--clr-primary-400);
    border-radius: 1.2rem;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    width: max-content;
    min-width: 20rem;
    min-height: 6rem;
    max-width: 25rem;
    box-shadow: inset 0px 4px 4px rgba(255, 255, 255, 0.12);
    font-weight: 700;
    line-height: 130%;
    text-align: center;
    color: var(--clr-default-400);
    transition: border var(--transition);

    &:hover {
      border: 0.1rem solid var(--clr-primary-500);
      transition: border var(--transition);
    }
  }

  @media (max-width: em(1200)) {
    margin-right: 2rem;
  }

  @media (max-width: em(575)) {
    display: none;
    margin-right: initial;
  }
}
