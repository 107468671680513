.cyber {
  &__wrapper {
    position: relative;
    display: flex;
    align-items: center;
  }

  &__content {
    max-width: 64rem;

    @media (max-width: em(1200)) {
      margin-right: 2rem;
      max-width: 50rem;
    }

    @media (max-width: em(992)) {
      margin-right: 0;
      max-width: initial;
    }
  }

  &__text {
    margin-bottom: 2.5rem;

    p:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }

  &__list {
    list-style: disc;
    margin-bottom: 5rem;
    padding-left: 2rem;
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }

  &__image {
    position: absolute;
    right: 0;
    top: 50%;
    z-index: -1;
    width: 66rem;
    height: 58rem;
    transform: translateY(-50%);

    @media (max-width: em(1200)) {
      position: initial;
      display: flex;
      align-items: center;
      transform: initial;
    }

    @media (max-width: em(992)) {
      display: none;
    }
  }
}
