.register {
  &__wrapper {
    display: flex;
    align-items: center;
  }

  &__content {
    margin-right: 2rem;
    max-width: 52rem;

    @media (max-width: em(1200)) {
      max-width: 45rem;
    }

    @media (max-width: em(992)) {
      margin-right: 0;
      max-width: initial;
    }
  }

  &__title {
    margin-bottom: 2.5rem;
    font-size: fluid-font(6.2rem, 3.4rem);
    line-height: 103%;
  }

  &__text {
    margin-bottom: 5rem;

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
    }
  }

  &__list {
    list-style: decimal;
    margin-bottom: 5rem;
    padding-left: 3rem;

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
      padding-left: 2rem;
    }
  }

  &__item {
    font-weight: 500;
    font-size: fluid-font(2.8rem, 1.8rem);

    &:not(:last-child) {
      margin-bottom: 3rem;
    }
  }

  &__sub-list {
    margin-top: 1.5rem;

    &:not(:last-child) {
      margin-bottom: 3rem;
    }
  }

  &__sub-item {
    position: relative;
    padding-left: 2rem;
    font-size: fluid-font(2rem, 1.6rem);
    line-height: 145%;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      border-radius: 50%;
      width: 0.4rem;
      height: 0.4rem;
      background-color: var(--clr-default-400);
      transform: translateY(-50%);
    }

    @media (max-width: em(767)) {
      padding-left: 1rem;
    }
  }

  &__image {
   max-width: 75rem;

    @media (max-width: em(992)) {
      display: none;
    }
  }
}
