.live {
  margin-top: 15rem;
  background-color: var(--clr-default-400);

  @media (max-width: em(992)) {
    margin-top: 8rem;
  }

  @media (max-width: em(575)) {
    margin-top: 5rem;
  }
  &__wrapper {
    display: flex;
    align-items: center;
    padding: 8rem 0;

    @media (max-width: em(767)) {
      padding: 6rem 0;
    }

    @media (max-width: em(575)) {
      padding: 3rem 0;
    }
  }

  &__content {
    margin-right: 2rem;
    max-width: 63rem;
    color: var(--clr-default-200);

    @media (max-width: em(1200)) {
      max-width: 50rem;
    }

    @media (max-width: em(992)) {
      margin-right: 0;
      max-width: initial;
    }
  }

  &__text {
    margin-bottom: 5rem;

    p:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }

  &__image {
    max-width: 63rem;

    @media (max-width: em(992)) {
      display: none;
    }
  }
}
