.benefits {

  &__title {
  }

  &__text {
    margin-bottom: 5rem;

    p:not(:last-child) {
      margin-bottom: 1.2rem;
    }

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
    }
  }

  &__table {
    overflow: auto;
    margin-bottom: 5rem;
    border-top: 1.2rem solid var(--clr-primary-400);
    border-radius: 1.2rem;
    padding-top: 4rem;
    padding-right: 3rem;
    padding-bottom: 4rem;
    padding-left: 3rem;
    color: var(--clr-default-200);
    background-color: var(--clr-default-400);

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
      padding-top: 2rem;
      padding-right: 1rem;
      padding-bottom: 2rem;
      padding-left: 1rem;
    }

    table {
      border-collapse: collapse;
      line-height: 150%;
      tr {
        border-bottom: 0.1rem solid rgba(255, 255, 255, 0.05);
      }

      td {
        padding-top: 1rem;
        padding-right: 1.2rem;
        padding-bottom: 1rem;
        padding-left: 1.2rem;

        a {
          color: var(--clr-default-200);
        }

        &:first-child {
          min-width: 32rem;

          @media (max-width: em(992)) {
            min-width: 25rem;
          }

          @media (max-width: em(575)) {
            min-width: 23rem;
          }
        }
      }

      @media (max-width: em(575)) {
        min-width: 50rem;
      }
    }
  }

  &__content-title {
    margin-bottom: 2.5rem;
    font-size: fluid-font(3.2rem, 2.4rem);
  }

  &__content {
    display: flex;

    @media (max-width: em(1200)) {
      flex-direction: column;
    }
  }

  &__list {
    &:first-child {
      margin-right: 2rem;
      min-width: 52rem;

      @media (max-width: em(1200)) {
        margin-right: initial;
        margin-bottom: 4rem;
        min-width: initial;
      }

      @media (max-width: em(575)) {
        margin-bottom: 3rem;
      }
    }

    &:last-child {
      min-width: 63rem;

      @media (max-width: em(1200)) {
        min-width: initial;
      }
    }
  }

  &__item {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 4.5rem;
    min-height: 3.2rem;

    &:not(:last-child) {
      margin-bottom: 2rem;
    }

    &--pros {
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 3.2rem;
        height: 3.2rem;
        background-image: url(../img/benefits/pros.svg);
        background-size: cover;
        background-repeat: no-repeat;
      }
    }

    &--cons {
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 3.2rem;
        height: 3.2rem;
        background-image: url(../img/benefits/cons.svg);
        background-size: cover;
        background-repeat: no-repeat;
      }
    }
  }

  &__item--pros {
  }
}

