.license {
  margin-top: 15rem;
  background-color: var(--clr-default-400);

  @media (max-width: em(992)) {
    margin-top: 8rem;
  }

  @media (max-width: em(575)) {
    margin-top: 5rem;
  }

  &__wrapper {
    position: relative;
    padding: 17rem 0;
    color: var(--clr-default-200);

    &::before {
      content: "";
      position: absolute;
      right: -27.5rem;
      bottom: 0;
      width: 85.5rem;
      height: 100%;
      background-image: -webkit-image-set(
        url(../img/license/bg@2x.png) 2x,
        url(../img/license/bg@1x.png) 1x
      );
      background-size: cover;
      background-repeat: no-repeat;

      @media (max-width: em(1780)) {
        display: none;
      }
    }

    @media (max-width: em(1200)) {
      padding: 10rem 0;
    }

    @media (max-width: em(768)) {
      padding: 6rem 0;
    }

    @media (max-width: em(575)) {
      padding: 3rem 0;
    }
  }

  &__content {
    max-width: 74rem;

    &:not(:last-child) {
      margin-bottom: 16rem;

      @media (max-width: em(1200)) {
        margin-bottom: 8rem;
      }

      @media (max-width: em(767)) {
        margin-bottom: 6rem;
      }

      @media (max-width: em(575)) {
        margin-bottom: 3rem;
      }
    }

    @media (max-width: em(1780)) {
      max-width: initial;
    }
  }

  &__title {
  }

  &__text {
    p:not(:last-child) {
      margin-bottom: 1.5em;
    }
  }

  &__link {
    margin-top: 5rem;

    @media (max-width: em(575)) {
      margin-top: 3rem;
    }
  }
}
