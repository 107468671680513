.enter {
  &__title {
    text-align: center;
  }

  &__text {
    margin-bottom: 5rem;
    text-align: center;

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
    }
  }

  &__link {
    margin: 0 auto;
  }
}
