.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.section {
  padding-top: 15rem;

  @media (max-width: em(992)) {
    padding-top: 8rem;
  }

  @media (max-width: em(575)) {
    padding-top: 5rem;
  }
}

.title {
  margin-bottom: 2.5rem;
  font-weight: 700;
  font-size: fluid-font(5.2rem, 3.4rem);
  line-height: 104%;
}

.description {
  font-weight: 400;
  line-height: 140%;
}

.main__link {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1.2rem;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  width: max-content;
  min-width: 28rem;
  min-height: 6rem;
  max-width: 35rem;
  font-weight: 600;
  font-size: 2rem;
  text-align: center;
  text-transform: uppercase;
  color: var(--clr-default-200);
  background-color: var(--clr-primary-400);
  transition: background-color var(--transition);

  @media (max-width: em(575)) {
    min-width: 100%;
  }

  &:hover {
    background-color: var(--clr-primary-300);
    transition: background-color var(--transition);
  }
}
