.result {
  &__wrapper {
    display: flex;
    align-items: center;
  }

  &__image {
    max-width: 67rem;

    @media (max-width: em(992)) {
      display: none;
    }
  }

  &__content {
    margin-left: 2rem;
    max-width: 63rem;
    @media (max-width: em(1200)) {
      max-width: 55rem;
    }

    @media (max-width: em(992)) {
      margin-left: 0;
      max-width: initial;
    }
  }

  &__text {
    p:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }
}
