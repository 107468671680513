// <МИКСИНЫ>==================================================================
@import "./settings/mixins";

// </МИКСИНЫ>=================================================================

// ФУНКЦИИ====================================================================
@import "./settings/functions";

// </ФУНКЦИИ>=================================================================

// <Подключаем шрифты>========================================================
@import "./settings/fonts";

// </Подключаем шрифты>=======================================================

// <ОБНУЛЕНИЕ>================================================================
@import "./settings/normalize";

// </ОБНУЛЕНИЕ>===============================================================

// <ПЕРЕМЕННЫЕ>===============================================================
$containerWidth: 129.5rem;

:root {
  // base
  --font-family: "Gilroy", sans-serif;
  --font-weight: 400;
  --content-width: 126.5rem;
  --container-offset: 1.5rem;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --transition: 0.3s;

  // colors
  --clr-default-100: #fff;
  --clr-default-200: #EEEFF3;
  --clr-default-400: #202F36;
  --clr-default-900: #000;
  --clr-primary-300: #09cea0;
  --clr-primary-400: #00A77F;
  --clr-primary-500: rgba(0, 167, 127, 0.25);
  --clr-primary-600: #202F36;
}

// </ПЕРЕМЕННЫЕ>==============================================================

// <ОБОЛОЧКИ>================================================================
body {
  font-weight: 400;
  font-size: fluid-font(2rem, 1.6rem);
  color: var(--clr-default-400);
  background-color: var(--clr-default-200);

  &.lock {
    position: fixed;
    left: 0;
    top: 0;
    overflow: hidden;
    width: 100%;
    height: 100vh;
    overscroll-behavior: none;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  min-width: 32rem;
  min-height: 100%;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 5;
    background-color: rgb(0 0 0 / 50%);
    opacity: 0;
    visibility: hidden;
    transition: opacity var(--transition), visibility var(--transition);
  }

  &[data-overlay="true"]::before {
    opacity: 1;
    visibility: visible;
    transition: opacity var(--transition), visibility var(--transition);
  }
}

main {
  flex-grow: 1;
}

// </ОБОЛОЧКИ>===============================================================

// <СЕТКА>===================================================================
.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: var(--container-offset);
  padding-left: var(--container-offset);
  width: 100%;
  max-width: var(--container-width);
}

// Components - UI
@import "./ui";

// Components - UI

// Components - UI
@import "./components/ui/menu";
@import "./components/ui/logo";
@import "./components/ui/logo-footer";

@import "./components/ui/get";
@import "./components/ui/burger";

// Components - UI

// Components - Common
@import "./components/common/header";
@import "./components/common/footer";

// Components - Common

// Components - Pages
@import "./components/indexPage/hero";
@import "./components/indexPage/benefits";
@import "./components/indexPage/register";
@import "./components/indexPage/bet";
@import "./components/indexPage/live";
@import "./components/indexPage/result";
@import "./components/indexPage/cyber";
@import "./components/indexPage/offer";
@import "./components/indexPage/app";
@import "./components/indexPage/online";
@import "./components/indexPage/games";
@import "./components/indexPage/bonus";
@import "./components/indexPage/loyalty";
@import "./components/indexPage/programm";
@import "./components/indexPage/enter";
@import "./components/indexPage/payment";
@import "./components/indexPage/license";
@import "./components/indexPage/resume";
@import "./components/common/faq";

// Components - Pages

// </СЕТКА>==================================================================

// <ПОДКЛЮЧЕНИЕ ФАЙЛОВ СТИЛЕЙ СТРАНИЦ И ЭЛЕМЕНТОВ>===========================

// <ПОДКЛЮЧЕНИЕ ФАЙЛОВ СТИЛЕЙ СТРАНИЦ И ЭЛЕМЕНТОВ>===========================
