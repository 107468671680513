.payment {

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: em(992)) {
      flex-direction: column;
    }
  }

  &__content {
    margin-right: 2rem;
    max-width: 96rem;

    @media (max-width: em(1200)) {
      max-width: 65rem;
    }

    @media (max-width: em(992)) {
      margin-right: 0;
      margin-bottom: 5rem;
      max-width: initial;
    }

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
    }
  }

  &__title {
  }

  &__text {
  }

  &__image {
    max-width: 20rem;

    // @media (max-width: em(992)) {
    //   display: none;
    // }
  }
}
