/* stylelint-disable */

/* stylelint-disable */

/* stylelint-disable scss/operator-no-unspaced */

/* stylelint-disable scss/dollar-variable-colon-newline-after */

@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy-Bold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy-Medium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Bold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy-Semibold.woff2") format("woff2");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Medium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

html {
  font-family: var(--font-family);
  font-weight: var(--font-weight);
  font-size: 62.5%;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth;
}

html,
body {
  height: 100%;
}

body {
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  text-decoration: none;
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

button {
  border: none;
  text-align: left;
  background-color: transparent;
  cursor: pointer;
}

ul,
ol {
  list-style: none;
}

input {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;
}

input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
  display: none;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.focus-visible {
  outline: auto 1px;
}

.ios .lock {
  position: relative;
}

/* Forms
   ========================================================================== */

/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */

button,
select {
  text-transform: none;
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

/**
 * Remove the inner border and padding in Firefox.
 */

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus styles unset by the previous rule.
 */

button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

:root {
  --font-family: "Gilroy", sans-serif;
  --font-weight: 400;
  --content-width: 126.5rem;
  --container-offset: 1.5rem;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --transition: 0.3s;
  --clr-default-100: #fff;
  --clr-default-200: #EEEFF3;
  --clr-default-400: #202F36;
  --clr-default-900: #000;
  --clr-primary-300: #09cea0;
  --clr-primary-400: #00A77F;
  --clr-primary-500: rgba(0, 167, 127, 0.25);
  --clr-primary-600: #202F36;
}

body {
  font-weight: 400;
  font-size: clamp(
    1.6rem,
    1.4687179487rem + 0.4102564103vw,
    2rem
  );
  color: var(--clr-default-400);
  background-color: var(--clr-default-200);
}

body.lock {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  min-width: 32rem;
  min-height: 100%;
}

.wrapper::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: opacity var(--transition), visibility var(--transition);
}

.wrapper[data-overlay=true]::before {
  opacity: 1;
  visibility: visible;
  transition: opacity var(--transition), visibility var(--transition);
}

main {
  flex-grow: 1;
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: var(--container-offset);
  padding-left: var(--container-offset);
  width: 100%;
  max-width: var(--container-width);
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.section {
  padding-top: 15rem;
}

.title {
  margin-bottom: 2.5rem;
  font-weight: 700;
  font-size: clamp(
    3.4rem,
    2.8092307692rem + 1.8461538462vw,
    5.2rem
  );
  line-height: 104%;
}

.description {
  font-weight: 400;
  line-height: 140%;
}

.main__link {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1.2rem;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  width: max-content;
  min-width: 28rem;
  min-height: 6rem;
  max-width: 35rem;
  font-weight: 600;
  font-size: 2rem;
  text-align: center;
  text-transform: uppercase;
  color: var(--clr-default-200);
  background-color: var(--clr-primary-400);
  transition: background-color var(--transition);
}

.main__link:hover {
  background-color: var(--clr-primary-300);
  transition: background-color var(--transition);
}

.menu {
  margin-right: 5rem;
  margin-left: auto;
}

.menu__list {
  display: flex;
  align-items: center;
}

.menu__item {
  position: relative;
}

.menu__item::before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -4px;
  width: 0;
  height: 2px;
  background-color: var(--clr-primary-400);
  transform: translate(-50%, 0);
  transition: width 0.3s;
}

.menu__item:hover::before {
  width: 100%;
  transition: width 0.3s;
}

.menu__item:not(:last-child) {
  margin-right: 2rem;
}

.menu__item a {
  font-weight: 500;
  color: var(--clr-default-400);
}

.menu .get {
  display: none;
}

.logo {
  margin-right: 2rem;
  max-width: 12.6rem;
}

.logo-footer {
  max-width: 13.5rem;
}

.get {
  margin-left: 2rem;
}

.get a {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.1rem solid var(--clr-primary-400);
  border-radius: 1.2rem;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  width: max-content;
  min-width: 20rem;
  min-height: 6rem;
  max-width: 25rem;
  box-shadow: inset 0px 4px 4px rgba(255, 255, 255, 0.12);
  font-weight: 700;
  line-height: 130%;
  text-align: center;
  color: var(--clr-default-400);
  transition: border var(--transition);
}

.get a:hover {
  border: 0.1rem solid var(--clr-primary-500);
  transition: border var(--transition);
}

.burger {
  display: none;
}

.header {
  display: flex;
  align-items: center;
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.header__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header__items {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.footer {
  margin-bottom: 15rem;
}

.footer__wrapper-logo {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5rem;
}

.footer__text {
  margin-bottom: 5rem;
  font-size: 1.6rem;
  color: var(--clr-default-900);
}

.footer__text p:not(:last-child) {
  margin-bottom: 1.5rem;
}

.footer__license {
  font-size: 1.4rem;
  text-align: center;
}

.hero {
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  border-radius: 2.4rem;
  max-width: 155rem;
  background-image: linear-gradient(103.61deg, #202f36 0%, #0b8475 99.76%);
}

.hero__wrapper {
  padding-top: 8rem;
  padding-bottom: 8rem;
  color: var(--clr-default-200);
}

.hero__content {
  max-width: 63rem;
}

.hero__title {
  margin-bottom: 2.5rem;
  font-size: clamp(
    3.4rem,
    2.481025641rem + 2.8717948718vw,
    6.2rem
  );
}

.hero__text {
  margin-bottom: 5rem;
}

.hero__text p:not(:last-child) {
  margin-bottom: 2rem;
}

.hero__image {
  position: absolute;
  right: -4.3rem;
  top: 50%;
  width: 76rem;
  height: 77rem;
  transform: translateY(-50%);
}

.benefits__text {
  margin-bottom: 5rem;
}

.benefits__text p:not(:last-child) {
  margin-bottom: 1.2rem;
}

.benefits__table {
  overflow: auto;
  margin-bottom: 5rem;
  border-top: 1.2rem solid var(--clr-primary-400);
  border-radius: 1.2rem;
  padding-top: 4rem;
  padding-right: 3rem;
  padding-bottom: 4rem;
  padding-left: 3rem;
  color: var(--clr-default-200);
  background-color: var(--clr-default-400);
}

.benefits__table table {
  border-collapse: collapse;
  line-height: 150%;
}

.benefits__table table tr {
  border-bottom: 0.1rem solid rgba(255, 255, 255, 0.05);
}

.benefits__table table td {
  padding-top: 1rem;
  padding-right: 1.2rem;
  padding-bottom: 1rem;
  padding-left: 1.2rem;
}

.benefits__table table td a {
  color: var(--clr-default-200);
}

.benefits__table table td:first-child {
  min-width: 32rem;
}

.benefits__content-title {
  margin-bottom: 2.5rem;
  font-size: clamp(
    2.4rem,
    2.1374358974rem + 0.8205128205vw,
    3.2rem
  );
}

.benefits__content {
  display: flex;
}

.benefits__list:first-child {
  margin-right: 2rem;
  min-width: 52rem;
}

.benefits__list:last-child {
  min-width: 63rem;
}

.benefits__item {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 4.5rem;
  min-height: 3.2rem;
}

.benefits__item:not(:last-child) {
  margin-bottom: 2rem;
}

.benefits__item--pros::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 3.2rem;
  height: 3.2rem;
  background-image: url(../img/benefits/pros.svg);
  background-size: cover;
  background-repeat: no-repeat;
}

.benefits__item--cons::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 3.2rem;
  height: 3.2rem;
  background-image: url(../img/benefits/cons.svg);
  background-size: cover;
  background-repeat: no-repeat;
}

.register__wrapper {
  display: flex;
  align-items: center;
}

.register__content {
  margin-right: 2rem;
  max-width: 52rem;
}

.register__title {
  margin-bottom: 2.5rem;
  font-size: clamp(
    3.4rem,
    2.481025641rem + 2.8717948718vw,
    6.2rem
  );
  line-height: 103%;
}

.register__text {
  margin-bottom: 5rem;
}

.register__list {
  list-style: decimal;
  margin-bottom: 5rem;
  padding-left: 3rem;
}

.register__item {
  font-weight: 500;
  font-size: clamp(
    1.8rem,
    1.4717948718rem + 1.0256410256vw,
    2.8rem
  );
}

.register__item:not(:last-child) {
  margin-bottom: 3rem;
}

.register__sub-list {
  margin-top: 1.5rem;
}

.register__sub-list:not(:last-child) {
  margin-bottom: 3rem;
}

.register__sub-item {
  position: relative;
  padding-left: 2rem;
  font-size: clamp(
    1.6rem,
    1.4687179487rem + 0.4102564103vw,
    2rem
  );
  line-height: 145%;
}

.register__sub-item::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  border-radius: 50%;
  width: 0.4rem;
  height: 0.4rem;
  background-color: var(--clr-default-400);
  transform: translateY(-50%);
}

.register__image {
  max-width: 75rem;
}

.bet__text {
  margin-bottom: 5rem;
}

.bet__text p:not(:last-child) {
  margin-bottom: 2rem;
}

.bet__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5rem;
}

.bet__list {
  margin-right: 3rem;
  min-width: 30rem;
}

.bet__list-item {
  position: relative;
  padding-left: 2rem;
}

.bet__list-item::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  border-radius: 50%;
  width: 0.4rem;
  height: 0.4rem;
  background-color: var(--clr-default-400);
  transform: translateY(-50%);
}

.bet__list-item:not(:last-child) {
  margin-bottom: 0.5rem;
}

.bet__image {
  max-width: 96rem;
}

.bet__bottom-text {
  margin-bottom: 1.5rem;
}

.bet__bottom-list {
  margin-bottom: 5rem;
  font-weight: 500;
}

.bet__bottom-item {
  position: relative;
  padding-left: 2rem;
}

.bet__bottom-item::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  border-radius: 50%;
  width: 0.4rem;
  height: 0.4rem;
  background-color: var(--clr-default-400);
  transform: translateY(-50%);
}

.bet__bottom-item:not(:last-child) {
  margin-bottom: 0.5rem;
}

.live {
  margin-top: 15rem;
  background-color: var(--clr-default-400);
}

.live__wrapper {
  display: flex;
  align-items: center;
  padding: 8rem 0;
}

.live__content {
  margin-right: 2rem;
  max-width: 63rem;
  color: var(--clr-default-200);
}

.live__text {
  margin-bottom: 5rem;
}

.live__text p:not(:last-child) {
  margin-bottom: 1.5rem;
}

.live__image {
  max-width: 63rem;
}

.result__wrapper {
  display: flex;
  align-items: center;
}

.result__image {
  max-width: 67rem;
}

.result__content {
  margin-left: 2rem;
  max-width: 63rem;
}

.result__text p:not(:last-child) {
  margin-bottom: 1.5rem;
}

.cyber__wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.cyber__content {
  max-width: 64rem;
}

.cyber__text {
  margin-bottom: 2.5rem;
}

.cyber__text p:not(:last-child) {
  margin-bottom: 1.5rem;
}

.cyber__list {
  list-style: disc;
  margin-bottom: 5rem;
  padding-left: 2rem;
}

.cyber__item:not(:last-child) {
  margin-bottom: 0.5rem;
}

.cyber__image {
  position: absolute;
  right: 0;
  top: 50%;
  z-index: -1;
  width: 66rem;
  height: 58rem;
  transform: translateY(-50%);
}

.offer__wrapper {
  display: flex;
  align-items: center;
}

.offer__content {
  margin-right: 2.5rem;
  max-width: 63rem;
}

.offer__image {
  max-width: 63rem;
}

.app__title {
  text-align: center;
}

.app__text {
  margin-bottom: 5rem;
}

.app__text p {
  text-align: center;
}

.app__text p:not(:last-child) {
  margin-bottom: 2rem;
}

.app__link {
  margin: 0 auto;
}

.online {
  margin-top: 15rem;
  color: var(--clr-default-200);
  background-color: var(--clr-default-400);
}

.online__text {
  margin-bottom: 2.5rem;
}

.online__image {
  margin-bottom: 2.5rem;
  max-width: 130rem;
}

.online__list-title {
  margin-bottom: 1.5rem;
  font-weight: 500;
  font-size: clamp(
    2.4rem,
    2.1374358974rem + 0.8205128205vw,
    3.2rem
  );
}

.online__list {
  list-style: disc;
  padding-left: 2.5rem;
}

.online__item:not(:last-child) {
  margin-bottom: 0.5rem;
}

.games {
  padding-bottom: 15rem;
  color: var(--clr-default-200);
  background-color: var(--clr-default-400);
}

.games__wrapper {
  display: flex;
  align-items: center;
}

.games__content {
  margin-right: 2rem;
  max-width: 63rem;
}

.games__text {
  margin-bottom: 1.5rem;
}

.games__list {
  list-style: disc;
  margin-bottom: 5rem;
  padding-left: 2rem;
  font-weight: 500;
}

.games__item:not(:last-child) {
  margin-bottom: 0.5rem;
}

.games__image {
  max-width: 90rem;
}

.bonus__image {
  position: relative;
  z-index: 2;
  margin-bottom: 2.5rem;
  max-width: 130rem;
}

.bonus__image::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 0;
  width: 68%;
  height: 53%;
  background-color: #00A77F;
  filter: blur(14rem);
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.bonus__image p {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 0;
  width: 80%;
  font-weight: 700;
  font-size: clamp(
    3.6rem,
    1.4994871795rem + 6.5641025641vw,
    10rem
  );
  text-align: center;
  text-transform: uppercase;
  color: var(--clr-default-200);
  transform: translate(-50%, -50%);
}

.bonus__text {
  margin-bottom: 8rem;
}

.bonus__text p:not(:last-child) {
  margin-bottom: 1.2rem;
}

.bonus__info {
  margin-bottom: 5rem;
}

.bonus__info-title {
  margin-bottom: 2.5rem;
  font-weight: 500;
  font-size: clamp(
    2.6rem,
    2.4030769231rem + 0.6153846154vw,
    3.2rem
  );
}

.bonus__info-subtitle {
  margin-bottom: 1.6rem;
  font-family: "Montserrat";
  font-weight: 700;
  font-size: clamp(
    1.8rem,
    1.6030769231rem + 0.6153846154vw,
    2.4rem
  );
  color: var(--clr-primary-400);
}

.bonus__list {
  list-style: disc;
  padding-left: 2rem;
  max-width: 41rem;
  font-family: "Montserrat";
  line-height: 145%;
}

.bonus__list--top .bonus__list-item:not(:last-child) {
  margin-bottom: 0.5rem;
}

.bonus__list-item {
  font-weight: 500;
}

.bonus__list-item:not(:last-child) {
  margin-bottom: 2rem;
}

.bonus__wrapper {
  display: grid;
  column-gap: 3rem;
  grid-template-columns: repeat(3, 1fr);
}

.loyalty__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.loyalty__content {
  margin-right: 2rem;
  max-width: 63rem;
}

.loyalty__text {
  margin-bottom: 5rem;
}

.loyalty__image {
  max-width: 52rem;
}

.programm__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5rem;
}

.programm__content {
  margin-right: 2.5rem;
  max-width: 63rem;
}

.programm__text p:not(:last-child) {
  margin-bottom: 1.5rem;
}

.programm__image {
  position: relative;
  max-width: 80rem;
}

.programm__image::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 0;
  width: 80%;
  height: 50%;
  background-color: #00a77f;
  filter: blur(14rem);
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.programm__image p {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 85%;
  font-weight: 700;
  font-size: clamp(
    2rem,
    0.6871794872rem + 4.1025641026vw,
    6rem
  );
  text-align: center;
  text-transform: uppercase;
  color: var(--clr-default-200);
  transform: translate(-50%, -50%);
}

.programm__table {
  position: relative;
  z-index: 1;
  overflow: auto;
  border-radius: 2.4rem;
  background-color: var(--clr-default-400);
}

.programm__table thead th {
  padding-top: 2rem;
  padding-right: 1rem;
  padding-bottom: 2rem;
  padding-left: 1rem;
  font-weight: 400;
  text-align: start;
  color: rgba(255, 255, 255, 0.66);
}

.programm__table thead th:first-child {
  padding-left: 4rem;
}

.programm__table table {
  border-collapse: collapse;
  min-width: 100%;
  color: var(--clr-default-200);
}

.programm__table table tr:nth-child(even) {
  background-color: var(--clr-primary-500);
}

.programm__table table tr td {
  padding-top: 2rem;
  padding-right: 1rem;
  padding-bottom: 2rem;
  padding-left: 1rem;
  min-width: 36rem;
}

.programm__table table tr td:first-child {
  padding-left: 4rem;
}

.enter__title {
  text-align: center;
}

.enter__text {
  margin-bottom: 5rem;
  text-align: center;
}

.enter__link {
  margin: 0 auto;
}

.payment__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.payment__content {
  margin-right: 2rem;
  max-width: 96rem;
}

.payment__image {
  max-width: 20rem;
}

.license {
  margin-top: 15rem;
  background-color: var(--clr-default-400);
}

.license__wrapper {
  position: relative;
  padding: 17rem 0;
  color: var(--clr-default-200);
}

.license__wrapper::before {
  content: "";
  position: absolute;
  right: -27.5rem;
  bottom: 0;
  width: 85.5rem;
  height: 100%;
  background-image: -webkit-image-set(url(../img/license/bg@2x.png) 2x, url(../img/license/bg@1x.png) 1x);
  background-size: cover;
  background-repeat: no-repeat;
}

.license__content {
  max-width: 74rem;
}

.license__content:not(:last-child) {
  margin-bottom: 16rem;
}

.license__text p:not(:last-child) {
  margin-bottom: 1.5em;
}

.license__link {
  margin-top: 5rem;
}

.resume__wrapper {
  display: flex;
  align-items: center;
}

.resume__content {
  margin-right: 2.5rem;
  max-width: 74rem;
}

.resume__text {
  margin-bottom: 5rem;
}

.resume__text p:not(:last-child) {
  margin-bottom: 2rem;
}

.faq__title {
  margin-bottom: 0;
  padding-bottom: 5rem;
}

.faq__wrapper:not(:last-child) {
  margin-bottom: 2rem;
}

.faq__wrapper.active .faq__question::before {
  height: 0;
  transition: height var(--transition);
}

.faq__wrapper.active .faq__answer {
  padding-top: 2.8rem;
  padding-bottom: 2.8rem;
  transition: max-height var(--transition), padding var(--transition);
}

.faq__question {
  position: relative;
  border-radius: 2.4rem;
  padding-top: 2.8rem;
  padding-right: 8rem;
  padding-bottom: 2.8rem;
  padding-left: 2.5rem;
  min-width: 100%;
  font-weight: 500;
  font-size: 1.8rem;
  text-align: start;
  color: var(--clr-default-200);
  background-color: var(--clr-primary-400);
}

.faq__question::before,
.faq__question::after {
  content: "";
  position: absolute;
  top: 50%;
  border-radius: 0.3rem;
  background-color: var(--clr-default-200);
  transform: translateY(-50%);
  transition: height var(--transition);
}

.faq__question::before {
  right: 3.5rem;
  width: 0.2rem;
  height: 2rem;
}

.faq__question::after {
  right: 2.6rem;
  width: 2rem;
  height: 0.2rem;
}

.faq__answer {
  overflow: hidden;
  padding-top: 0;
  padding-right: 1rem;
  padding-bottom: 0;
  padding-left: 2.5rem;
  max-height: 0;
  box-sizing: content-box;
  font-family: "Montserrat";
  font-size: 1.8rem;
  line-height: 150%;
  transition: max-height var(--transition), padding var(--transition);
}

@media (max-width: 75em) {
  .menu {
    margin-right: 0 !important;
  }

  .get {
    margin-right: 2rem;
  }

  .hero__content {
    max-width: 45rem;
  }

  .benefits__content {
    flex-direction: column;
  }

  .benefits__list:first-child {
    margin-right: initial;
    margin-bottom: 4rem;
    min-width: initial;
  }

  .benefits__list:last-child {
    min-width: initial;
  }

  .register__content {
    max-width: 45rem;
  }

  .live__content {
    max-width: 50rem;
  }

  .result__content {
    max-width: 55rem;
  }

  .cyber__content {
    margin-right: 2rem;
    max-width: 50rem;
  }

  .cyber__image {
    position: initial;
    display: flex;
    align-items: center;
    transform: initial;
  }

  .offer__content {
    max-width: 55rem;
  }

  .games__content {
    max-width: 45rem;
  }

  .loyalty__content {
    margin-right: 0;
    max-width: 55rem;
  }

  .programm__content {
    max-width: 50rem;
  }

  .programm__table table tr td {
    min-width: 26rem;
  }

  .payment__content {
    max-width: 65rem;
  }

  .license__wrapper {
    padding: 10rem 0;
  }

  .license__content:not(:last-child) {
    margin-bottom: 8rem;
  }

  .resume__content {
    max-width: 55rem;
  }
}

@media (max-width: 62em) {
  .section {
    padding-top: 8rem;
  }

  .header {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .footer {
    margin-bottom: 8rem;
  }

  .hero__wrapper {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .hero__content {
    max-width: initial;
  }

  .hero__image {
    display: none;
  }

  .benefits__table table td:first-child {
    min-width: 25rem;
  }

  .register__content {
    margin-right: 0;
    max-width: initial;
  }

  .register__image {
    display: none;
  }

  .bet__wrapper {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1rem;
  }

  .bet__list {
    margin-right: 0;
    margin-bottom: 5rem;
    min-width: initial;
  }

  .live {
    margin-top: 8rem;
  }

  .live__content {
    margin-right: 0;
    max-width: initial;
  }

  .live__image {
    display: none;
  }

  .result__image {
    display: none;
  }

  .result__content {
    margin-left: 0;
    max-width: initial;
  }

  .cyber__content {
    margin-right: 0;
    max-width: initial;
  }

  .cyber__image {
    display: none;
  }

  .offer__wrapper {
    flex-direction: column;
  }

  .offer__content {
    margin-right: 0;
    max-width: initial;
  }

  .offer__text {
    margin-bottom: 5rem;
  }

  .online {
    margin-top: 8rem;
  }

  .games {
    padding-bottom: 8rem;
  }

  .games__wrapper {
    flex-direction: column;
  }

  .games__content {
    margin-right: 0;
    max-width: initial;
  }

  .bonus__text {
    margin-bottom: 6rem;
  }

  .bonus__list {
    max-width: initial;
  }

  .bonus__wrapper {
    display: block;
    column-gap: initial;
  }

  .bonus__item:not(:last-child) {
    margin-bottom: 3rem;
  }

  .loyalty__wrapper {
    flex-direction: column;
  }

  .loyalty__content {
    margin-bottom: 5rem;
    max-width: initial;
  }

  .programm__content {
    margin-right: 0;
    max-width: initial;
  }

  .programm__image {
    display: none;
  }

  .programm__table table tr td {
    min-width: 18rem;
  }

  .payment__wrapper {
    flex-direction: column;
  }

  .payment__content {
    margin-right: 0;
    margin-bottom: 5rem;
    max-width: initial;
  }

  .license {
    margin-top: 8rem;
  }

  .resume__content {
    margin-right: 0;
    max-width: initial;
  }

  .resume__image {
    display: none;
  }
}

@media (max-width: 48em) {
  .license__wrapper {
    padding: 6rem 0;
  }
}

@media (max-width: 111.25em) {
  .license__wrapper::before {
    display: none;
  }

  .license__content {
    max-width: initial;
  }
}

@media (max-width: 93.75em) {
  .hero {
    border-radius: initial;
  }
}

@media (max-width: 87.5em) {
  .hero__image {
    width: 55rem;
    height: 55rem;
  }
}

@media (max-width: 80.625em) {
  .menu__list {
    flex-direction: column;
    margin: auto;
    padding-block: 4rem;
  }

  .menu__item:not(:last-child) {
    margin-right: 0;
    margin-bottom: 3rem;
  }

  .menu {
    position: fixed;
    right: -100%;
    top: 0;
    z-index: 10;
    display: flex;
    overflow: auto;
    width: 100%;
    height: 100%;
    max-width: 32rem;
    background-color: var(--clr-default-200);
    transition: right var(--transition);
  }

  .menu--active {
    right: 0;
    transition: right var(--transition);
  }

  .burger {
    position: relative;
    z-index: 15;
    display: block;
    margin-left: 2rem;
    width: 2.5rem;
    height: 2rem;
    background-color: transparent;
  }

  .burger::after,
  .burger::before,
  .burger span {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 0.4rem;
    background-color: var(--clr-primary-400);
  }

  .burger::before {
    top: 0;
    transition: transform var(--transition), top var(--transition);
  }

  .burger::after {
    bottom: 0;
    transition: transform var(--transition), bottom var(--transition);
  }

  .burger span {
    top: 0.8rem;
    transition: transform var(--transition);
  }

  .burger--active::before {
    top: 0.8rem;
    transform: rotate(45deg);
    transition: transform var(--transition), top var(--transition);
  }

  .burger--active::after {
    bottom: 0.8rem;
    transform: rotate(-45deg);
    transition: transform var(--transition), bottom var(--transition);
  }

  .burger--active span {
    transform: scale(0);
    transition: transform var(--transition);
  }
}

@media (max-width: 77.5em) {
  .menu {
    margin-right: 2rem;
  }
}

@media (max-width: 47.9375em) {
  .register__sub-item {
    padding-left: 1rem;
  }

  .live__wrapper {
    padding: 6rem 0;
  }

  .bonus__image::before {
    filter: blur(5rem);
  }

  .bonus__list-item:not(:last-child) {
    margin-bottom: 1rem;
  }

  .license__content:not(:last-child) {
    margin-bottom: 6rem;
  }
}

@media (max-width: 35.9375em) {
  .section {
    padding-top: 5rem;
  }

  .main__link {
    min-width: 100%;
  }

  .menu__item a {
    font-weight: 600;
    font-size: 2.4rem;
  }

  .menu .get {
    display: flex;
  }

  .logo {
    max-width: 10.6rem;
    max-height: 3rem;
  }

  .logo-footer {
    max-width: 10.6rem;
    max-height: 3rem;
  }

  .get {
    display: none;
    margin-right: initial;
  }

  .header {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .footer {
    margin-bottom: 5rem;
  }

  .footer__wrapper-logo {
    margin-bottom: 3rem;
  }

  .footer__text {
    margin-bottom: 3rem;
  }

  .hero__wrapper {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .benefits__text {
    margin-bottom: 3rem;
  }

  .benefits__table {
    margin-bottom: 3rem;
    padding-top: 2rem;
    padding-right: 1rem;
    padding-bottom: 2rem;
    padding-left: 1rem;
  }

  .benefits__table table td:first-child {
    min-width: 23rem;
  }

  .benefits__table table {
    min-width: 50rem;
  }

  .benefits__list:first-child {
    margin-bottom: 3rem;
  }

  .register__text {
    margin-bottom: 3rem;
  }

  .register__list {
    margin-bottom: 3rem;
    padding-left: 2rem;
  }

  .bet__text {
    margin-bottom: 3rem;
  }

  .bet__list {
    margin-bottom: 3rem;
  }

  .bet__bottom-list {
    margin-bottom: 3rem;
  }

  .live {
    margin-top: 5rem;
  }

  .live__wrapper {
    padding: 3rem 0;
  }

  .offer__text {
    margin-bottom: 3rem;
  }

  .app__text {
    margin-bottom: 3rem;
  }

  .online {
    margin-top: 5rem;
  }

  .games {
    padding-bottom: 1rem;
  }

  .games__list {
    margin-bottom: 3rem;
  }

  .bonus__image::before {
    filter: blur(3rem);
  }

  .bonus__text {
    margin-bottom: 3rem;
  }

  .bonus__info {
    margin-bottom: 3rem;
  }

  .loyalty__content {
    margin-bottom: 3rem;
  }

  .loyalty__text {
    margin-bottom: 3rem;
  }

  .programm__table thead th:first-child {
    padding-left: 1.5rem;
  }

  .programm__table table tr td:first-child {
    padding-left: 1.5rem;
  }

  .programm__table table tr td {
    min-width: 12rem;
  }

  .enter__text {
    margin-bottom: 3rem;
  }

  .payment__content {
    margin-bottom: 3rem;
  }

  .license {
    margin-top: 5rem;
  }

  .license__wrapper {
    padding: 3rem 0;
  }

  .license__content:not(:last-child) {
    margin-bottom: 3rem;
  }

  .license__link {
    margin-top: 3rem;
  }

  .resume__text {
    margin-bottom: 3rem;
  }

  .faq__title {
    padding-bottom: 3rem;
  }

  .faq__wrapper.active .faq__answer {
    padding-top: 1.8rem;
    padding-bottom: 1.8rem;
  }

  .faq__question::before {
    right: 2.5rem;
  }

  .faq__question::after {
    right: 1.6rem;
  }

  .faq__question {
    padding-top: 1.8rem;
    padding-right: 5rem;
    padding-bottom: 1.8rem;
    padding-left: 1.5rem;
  }

  .faq__answer {
    padding-left: 1.5rem;
  }
}