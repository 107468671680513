.programm {
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5rem;
  }

  &__content {
    margin-right: 2.5rem;
    max-width: 63rem;

    @media (max-width: em(1200)) {
      max-width: 50rem;
    }

    @media (max-width: em(992)) {
      margin-right: 0;
      max-width: initial;
    }
  }

  &__text {
    p:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }

  &__image {
    position: relative;
    max-width: 80rem;

    &::before {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      z-index: 0;
      width: 80%;
      height: 50%;
      background-color: #00a77f;
      filter: blur(14rem);
      transform: translate(-50%, -50%);
      pointer-events: none;
    }

    p {
      position: absolute;
      left: 50%;
      top: 50%;
      z-index: 1;
      width: 85%;
      font-weight: 700;
      font-size: fluid-font(6rem, 2rem);
      text-align: center;
      text-transform: uppercase;
      color: var(--clr-default-200);
      transform: translate(-50%, -50%);
    }

    @media (max-width: em(992)) {
      display: none;
    }
  }

  &__table {
    position: relative;
    z-index: 1;
    overflow: auto;
    border-radius: 2.4rem;
    background-color: var(--clr-default-400);

    thead {
      th {
        padding-top: 2rem;
        padding-right: 1rem;
        padding-bottom: 2rem;
        padding-left: 1rem;
        font-weight: 400;
        text-align: start;
        color: rgba(255, 255, 255, 0.66);

        &:first-child {
          padding-left: 4rem;

          @media (max-width: em(575)) {
            padding-left: 1.5rem;
          }
        }
      }
    }

    table {
      border-collapse: collapse;
      min-width: 100%;
      color: var(--clr-default-200);

      tr {
        &:nth-child(even) {
          background-color: var(--clr-primary-500);
        }

        td {
          padding-top: 2rem;
          padding-right: 1rem;
          padding-bottom: 2rem;
          padding-left: 1rem;
          min-width: 36rem;

          &:first-child {
            padding-left: 4rem;

            @media (max-width: em(575)) {
              padding-left: 1.5rem;
            }
          }

          @media (max-width: em(1200)) {
            min-width: 26rem;
          }

          @media (max-width: em(992)) {
            min-width: 18rem;
          }

          @media (max-width: em(575)) {
            min-width: 12rem;
          }
        }
      }
    }
  }
}
