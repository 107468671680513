.hero {
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  border-radius: 2.4rem;
  max-width: 155rem;
  background-image: linear-gradient(103.61deg, #202f36 0%, #0b8475 99.76%);

  @media (max-width: em(1500)) {
    border-radius: initial;
  }

  &__wrapper {
    padding-top: 8rem;
    padding-bottom: 8rem;
    color: var(--clr-default-200);

    @media (max-width: em(992)) {
      padding-top: 6rem;
      padding-bottom: 6rem;
    }

    @media (max-width: em(575)) {
      padding-top: 3rem;
      padding-bottom: 3rem;
    }
  }

  &__content {
    max-width: 63rem;

    @media (max-width: em(1200)) {
      max-width: 45rem;
    }

    @media (max-width: em(992)) {
      max-width: initial;
    }
  }

  &__title {
    margin-bottom: 2.5rem;
    font-size: fluid-font(6.2rem, 3.4rem);
  }

  &__text {
    margin-bottom: 5rem;

    p:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  &__image {
    position: absolute;
    right: -4.3rem;
    top: 50%;
    width: 76rem;
    height: 77rem;
    transform: translateY(-50%);

    @media (max-width: em(1400)) {
      width: 55rem;
      height: 55rem;
    }

    @media (max-width: em(992)) {
      display: none;
    }
  }
}
