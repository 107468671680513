.online {
  margin-top: 15rem;
  color: var(--clr-default-200);
  background-color: var(--clr-default-400);

  @media (max-width: em(992)) {
    margin-top: 8rem;
  }

  @media (max-width: em(575)) {
    margin-top: 5rem;
  }

  &__text {
    margin-bottom: 2.5rem;
  }

  &__image {
    margin-bottom: 2.5rem;
    max-width: 130rem;
  }

  &__list-title {
    margin-bottom: 1.5rem;
    font-weight: 500;
    font-size: fluid-font(3.2rem, 2.4rem);
  }

  &__list {
    list-style: disc;
    padding-left: 2.5rem;
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
}

