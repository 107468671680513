.bet {

  &__title {
  }

  &__text {
    margin-bottom: 5rem;

    p:not(:last-child) {
      margin-bottom: 2rem;
    }

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5rem;

    @media (max-width: em(992)) {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 1rem;
    }
  }

  &__list {
    margin-right: 3rem;
    min-width: 30rem;

    @media (max-width: em(992)) {
      margin-right: 0;
      margin-bottom: 5rem;
      min-width: initial;
    }

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
    }
  }

  &__list-item {
    position: relative;
    padding-left: 2rem;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      border-radius: 50%;
      width: 0.4rem;
      height: 0.4rem;
      background-color: var(--clr-default-400);
      transform: translateY(-50%);
    }

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }

  &__image {
    max-width: 96rem;
  }

  &__bottom-text {
    margin-bottom: 1.5rem;
  }

  &__bottom-list {
    margin-bottom: 5rem;
    font-weight: 500;

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
    }
  }

  &__bottom-item {
    position: relative;
    padding-left: 2rem;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      border-radius: 50%;
      width: 0.4rem;
      height: 0.4rem;
      background-color: var(--clr-default-400);
      transform: translateY(-50%);
    }

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
}

