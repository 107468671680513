.bonus {
  &__image {
    position: relative;
    z-index: 2;
    margin-bottom: 2.5rem;
    max-width: 130rem;

    &::before {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      z-index: 0;
      width: 68%;
      height: 53%;
      background-color: #00A77F;
      filter: blur(14rem);
      transform: translate(-50%, -50%);
      pointer-events: none;

      @media (max-width: em(767)) {
        filter: blur(5rem);
      }

      @media (max-width: em(575)) {
        filter: blur(3rem);
      }
    }

    p {
      position: absolute;
      left: 50%;
      top: 50%;
      z-index: 0;
      width: 80%;
      font-weight: 700;
      font-size: fluid-font(10rem, 3.6rem);
      text-align: center;
      text-transform: uppercase;
      color: var(--clr-default-200);
      transform: translate(-50%, -50%);
    }
  }

  &__text {
    margin-bottom: 8rem;

    p:not(:last-child) {
      margin-bottom: 1.2rem;
    }

    @media (max-width: em(992)) {
      margin-bottom: 6rem;
    }

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
    }
  }

  &__info {
    margin-bottom: 5rem;

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
    }
  }

  &__info-title {
    margin-bottom: 2.5rem;
    font-weight: 500;
    font-size: fluid-font(3.2rem, 2.6rem);
  }

  &__info-subtitle {
    margin-bottom: 1.6rem;
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: fluid-font(2.4rem, 1.8rem);
    color: var(--clr-primary-400);
  }

  &__list {
    list-style: disc;
    padding-left: 2rem;
    max-width: 41rem;
    font-family: 'Montserrat';
    line-height: 145%;

    &--top {
      .bonus__list-item {
        &:not(:last-child) {
          margin-bottom: 0.5rem;
        }
      }
    }

    @media (max-width: em(992)) {
      max-width: initial;
    }
  }

  &__list-item {
    font-weight: 500;

    &:not(:last-child) {
      margin-bottom: 2rem;

      @media (max-width: em(767)) {
        margin-bottom: 1rem;
      }
    }
  }

  &__wrapper {
    display: grid;
    column-gap: 3rem;
    grid-template-columns: repeat(3, 1fr);

    @media (max-width: em(992)) {
      display: block;
      column-gap: initial;
    }
  }

  &__item {
    @media (max-width: em(992)) {
      &:not(:last-child) {
        margin-bottom: 3rem;
      }
    }
  }
}
