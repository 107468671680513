.header {
  display: flex;
  align-items: center;
  padding-top: 4rem;
  padding-bottom: 4rem;

  @media (max-width: em(992)) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  @media (max-width: em(575)) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__items {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
}
