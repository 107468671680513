.app {
  &__title {
    text-align: center;
  }

  &__text {
    margin-bottom: 5rem;

    p {
      text-align: center;

      &:not(:last-child) {
        margin-bottom: 2rem;
      }
    }

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
    }
  }

  &__link {
    margin: 0 auto;
  }
}

