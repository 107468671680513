.games {
  padding-bottom: 15rem;
  color: var(--clr-default-200);
  background-color: var(--clr-default-400);

  @media (max-width: em(992)) {
    padding-bottom: 8rem;
  }

  @media (max-width: em(575)) {
    padding-bottom: 1rem;
  }

  &__wrapper {
    display: flex;
    align-items: center;

    @media (max-width: em(992)) {
      flex-direction: column;
    }
  }

  &__content {
    margin-right: 2rem;
    max-width: 63rem;

    @media (max-width: em(1200)) {
      max-width: 45rem;
    }

    @media (max-width: em(992)) {
      margin-right: 0;
      max-width: initial;
    }
  }

  &__text {
    margin-bottom: 1.5rem;
  }

  &__list {
    list-style: disc;
    margin-bottom: 5rem;
    padding-left: 2rem;
    font-weight: 500;

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
    }
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }

  &__image {
    max-width: 90rem;

    // @media (max-width: em(992)) {
    //   display: none;
    // }
  }
}

