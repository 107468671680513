.resume {
  &__wrapper {
    display: flex;
    align-items: center;
  }

  &__content {
    margin-right: 2.5rem;
    max-width: 74rem;

    @media (max-width: em(1200)) {
      max-width: 55rem;
    }

    @media (max-width: em(992)) {
      margin-right: 0;
      max-width: initial;
    }
  }

  &__text {
    margin-bottom: 5rem;

    p:not(:last-child) {
      margin-bottom: 2rem;
    }

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
    }
  }

  &__image {
    @media (max-width: em(992)) {
      display: none;
    }
  }
}

